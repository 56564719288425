import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getProjectList, getStructure as _getStructure, getProjectAdd, getProjectEdit, getProjectRead, checkNameIsExists, getRankGroupList, getRankGroupStructre } from '@/api/ceshi_xiugai/popularize';
import { getUserList as _getUserList } from '@/api/updateUserInfo/structure';
import Head from '@/components/head/index';
// import TimeScreeningse from '@/components/timeScreeningse';
// import privateSea from '@/components/privateSea';
import MyExportExcel from '@/components/MyExportExcel/index';
import mailListEs from '@/components/StaffSelect/mailListRadioEs';
import mailListment from '@/components/StaffSelect/mailListRadioment';
import mailListData from '@/components/StaffSelect/mailListRadioData';
import { getInfo } from '@/api/login';
import cities from '@/static/common_js/citys.json';
export default {
  name: 'extensionProject',
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      dialogProject: false,
      //系统教程
      title: '推广项目管理',
      reverse: true,
      val: '',
      type: '',
      directionData: '',
      tgDepart: '',
      tgDeparts: '',
      tgDepartSearch: '',
      customer_create: '',
      enterTime: '',
      enterTimeShow: false,
      screate_time: '',
      ecreate_time: '',
      coustomer: [],
      bumenoptions: [],
      //部门
      coustomer_create_id: [],
      coustomerName: '',
      loading: false,
      directionShow: false,
      enterShow: false,
      tgbmShow: false,
      // 列表请求参数
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      directions: [{
        value: '1',
        label: '定向数据'
      }, {
        value: '2',
        label: '非定向数据'
      }],
      projectSearch: '',
      //搜索
      enterer: '',
      // 列表请求参数
      ptionse: 1,
      listLoading: true,
      //加载动画
      tableData: [],
      //列表
      ruleForm: {
        id: '',
        name: '',
        opti: 1,
        is_directional: 1,
        rank_group_id: '',
        bumenLabel: [{
          structre_id: '',
          name: '',
          addres: '',
          childdren_c: [],
          childdren_a: '',
          structure_share: [],
          distribution_way: 1,
          popularize_project_structure: []
        }]
      },
      rules: {
        name: [{
          required: true,
          message: '请填写推广项目名称',
          trigger: 'blur'
        }, {
          max: 500,
          message: '长度在 500 个字符以内',
          trigger: 'blur'
        }],
        structure_share: [{
          type: 'array',
          required: true,
          message: '请至少选择一个推广适用部门',
          trigger: 'change'
        }],
        is_directional: [{
          required: true,
          message: '请选择定向数据',
          trigger: 'change'
        }]
      },
      charge_type: '',
      vals: '',
      fullscreenLoading: false,
      cityArray: [],
      optionss: [{
        id: 1,
        name: '半自动'
      }, {
        id: 2,
        name: '全自动'
      }],
      array2: [],
      ids: '',
      // 定级组列表
      getRankGroupListData: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
      this.callname = this.$store.getters.callname;
      this.callpass = this.$store.getters.callpass;
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this2.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      this.getStructure();
      this.getUserList();
      cities.map(function (item) {
        item.disabled = false;
      });
      this.cityArray = [{
        label: '不限',
        value: '0',
        disabled: false,
        pid: 1
      }].concat(_toConsumableArray(cities));
      getInfo().then(function (res) {
        _this2.charge_type = res.data.charge_type;
      });
      // 获取定级组列表
      this.getRankGroupListFun();
    } else {}
  },
  components: {
    Head: Head,
    mailListEs: mailListEs,
    mailListData: mailListData,
    mailListment: mailListment,
    MyExportExcel: MyExportExcel
  },
  methods: {
    // 获取定级组列表
    getRankGroupListFun: function getRankGroupListFun() {
      var _this3 = this;
      getRankGroupList().then(function (respomse) {
        _this3.getRankGroupListData = respomse.data;
      });
    },
    handleEdit: function handleEdit(val, key) {
      var _this4 = this;
      //  新增/新增子栏目/编辑弹窗

      if (val == 'edit') {
        this.vals = '编辑推广项目';
        this.dialogProject = true;
        this.$nextTick(function () {
          _this4.$refs['ruleForm'].clearValidate();
        });
        this.ruleForm.id = key.id;
        // this.cityArray.forEach((item, index) => {
        //     item.disabled = false;
        // });
        getProjectRead({
          id: String(key.id)
        }).then(function (respomse) {
          var data = respomse.data;
          _this4.ruleForm.id = data.id;
          _this4.ruleForm.name = data.name;
          _this4.ruleForm.is_directional = data.is_directional;
          _this4.ruleForm.coustomer_create_id = data.coustomer_create_id;
          var arrGroupId = [];
          var structure_share_str = data.structure_share_str;
          _this4.ruleForm.bumenLabel = structure_share_str;
          _this4.ruleForm.bumenLabel.forEach(function (item, index) {
            var bumenArr = [];
            if (item.distribution_way == 1) {
              // 部门用于回显 拼成二维数组
              item.popularize_project_structure.forEach(function (it, ind) {
                bumenArr.push(it.structre_ids.split(','));
              });
              // 归属地处理
              if (item.addres == 0 || item.addres == '') {
                item.addres = '0';
              }
              _this4.$set(_this4.ruleForm.bumenLabel[index], 'structure_share', bumenArr);
            } else {
              arrGroupId.push(item.rank_group_id);
              _this4.ruleForm.rank_group_id = arrGroupId.toString();
              // this.ruleForm.rank_group_id = item.rank_group_id;
              item.popularize_project_structure = item.structre_arr;
              // 归属地数据处理
              if (item.rank_group_addres == 0 || item.rank_group_addres == '') {
                item.rank_group_addres = '0';
              }
            }
            // 归属地用于回显
            var brr = [];
            _this4.cityArray.forEach(function (res_y) {
              if (item.distribution_way == 1) {
                if (item.addres == '' || item.addres == '0') {
                  brr.push(['0']);
                }
              } else {
                if (item.rank_group_addres == '' || item.rank_group_addres == '0') {
                  brr.push(['0']);
                }
              }
              res_y.children && res_y.children.forEach(function (res_z) {
                if (item.distribution_way == 1) {
                  if (item.addres.indexOf(res_z.label) != -1) {
                    brr.push([res_y.value, res_z.value]);
                  }
                } else {
                  if (item.rank_group_addres.indexOf(res_z.label) != -1) {
                    brr.push([res_y.value, res_z.value]);
                  }
                }
              });
            });
            _this4.$set(_this4.ruleForm.bumenLabel[index], 'childdren_c', brr);
          });
          // structure_share_str.forEach((res) => {
          //     res.popularize_project_structure.forEach((item) => {
          //         arr.push(item.user_ids);
          //     });
          //     res.name = this.bumenoptions[this.bumenoptions.map((res) => res.id).indexOf(res.structre_id)]
          //         ? this.bumenoptions[this.bumenoptions.map((res) => res.id).indexOf(res.structre_id)].name
          //         : '';
          //     res.cityArray = JSON.parse(JSON.stringify(this.cityArray));
          //     let brr = [];
          //     res.cityArray.forEach((res_y) => {
          //         if (res.addres == '') {
          //             brr.push(['']);
          //             res.cityArray.forEach((item) => {
          //                 if (item.label != '不限') {
          //                     item.disabled = true;
          //                 } else {
          //                     item.disabled = false;
          //                 }
          //             });
          //         }
          //         res_y.children &&
          //             res_y.children.forEach((res_z) => {
          //                 if (res.addres.indexOf(res_z.label) != -1) {
          //                     brr.push([res_y.value, res_z.value]);
          //                     res.cityArray.forEach((item) => {
          //                         if (item.label == '不限') {
          //                             item.disabled = true;
          //                             return;
          //                         }
          //                     });
          //                     return;
          //                 }
          //             });
          //         res.childdren_c = brr;
          //     });
          // });
          // this.ruleForm.bumenLabel.structure_share = arr
          //     .toString()
          //     .split(',')
          //     .map((e) => {
          //         const { suid } = this.getDepartmentNameBySuid(e, this.bumenoptions);
          //         return suid;
          //     });
          // this.ruleForm.bumenLabel = structure_share_str;
        });
      } else if (val == 'newly') {
        this.vals = '新增推广项目';
        this.dialogProject = true;
        this.ruleForm.id = '';
        this.ruleForm = {
          id: '',
          name: '',
          opti: 1,
          is_directional: 1,
          rank_group_id: '',
          bumenLabel: [{
            structre_id: '',
            name: '',
            addres: '',
            childdren_c: [],
            childdren_a: '',
            structure_share: [],
            distribution_way: 1,
            popularize_project_structure: []
          }]
        };
        // this.cityArray.forEach((item, index) => {
        //     if (index > 0) {
        //         item.disabled = true;
        //     }
        // });
      }
    },
    checkName: function checkName() {
      var _this5 = this;
      checkNameIsExists({
        name: this.ruleForm.name,
        id: this.ruleForm.id
      }).then(function (res) {
        _this5.listLoading = false;
      }).catch(function () {
        _this5.ruleForm.name = '';
      });
    },
    optisea: function optisea(index, val) {
      // 判断当前已经有一条全自动 就不让选择全自动
      // let j = 0;
      // for (let i = 0; i < this.ruleForm.bumenLabel.length; i++) {
      //     if (this.ruleForm.bumenLabel[i].distribution_way == 2) {
      //         j++;
      //         if (j == 2) {
      //             this.$message({
      //                 message: '只支持有一条全自动分配数据',
      //                 type: 'warning'
      //             });
      //             this.ruleForm.bumenLabel[index].distribution_way = 1;
      //             break;
      //         }
      //     }
      // }

      if (this.ruleForm.bumenLabel[index].distribution_way == 2) {
        var obj = {
          rank_group_id: '',
          name: '',
          rank_group_addres: '',
          distribution_way: 2
        };
        this.$set(this.ruleForm.bumenLabel, index, obj);
      } else {
        var _obj = {
          structre_id: '',
          name: '',
          addres: '',
          childdren_c: [],
          childdren_a: '',
          structure_share: [],
          distribution_way: 1,
          popularize_project_structure: []
        };
        this.$set(this.ruleForm.bumenLabel, index, _obj);
      }
    },
    classification: function classification() {
      var category = {
        structre_id: '',
        name: '',
        addres: '',
        distribution_way: 1,
        popularize_project_structure: []
      };
      this.ruleForm.bumenLabel.push(category);
    },
    DeleteCondition: function DeleteCondition(key) {
      this.ruleForm.bumenLabel.splice(key, 1);
    },
    createData: function createData(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // let aaa = [];
          // console.log(this.cityArray, this.ruleForm.bumenLabel);
          // this.cityArray.forEach((item) => {
          //     this.ruleForm.bumenLabel.forEach((even) => {
          //         even.childdren_c.forEach((res) => {
          //             if (item.value == res[0]) {
          //                 aaa.push(item.label);
          //             }
          //         });
          //     });
          // });
          _this6.ruleForm.structure_share_str = _this6.ruleForm.bumenLabel;
          // this.ruleForm.structure_share_str.forEach((res) => {
          //     delete res.cityArray;
          //     delete res.childdren_c;
          // });
          // for (let i = 0; i < this.ruleForm.bumenLabel.length; i++) {
          //     if (Object.prototype.toString.call(this.ruleForm.bumenLabel[i].addres) == '[object Array]') {
          //         this.ruleForm.bumenLabel[i].addres = this.ruleForm.bumenLabel[i].addres.join(',');
          //     }
          // }
          // console.log('this.ruleForm', this.ruleForm);
          var structure_share_str = [];
          var rank_group_id_arr = [];
          var validFlag = false;
          var isAllAuto = false;
          try {
            _this6.ruleForm.structure_share_str.forEach(function (item, index) {
              if (item.distribution_way == 1) {
                var obj = {
                  structre_id: item.structre_id,
                  name: item.name,
                  popularize_project_structure: item.popularize_project_structure,
                  addres: item.addres != 0 ? item.addres : '',
                  distribution_way: item.distribution_way
                };
                if (item.addres == '') {
                  throw Error('请检查半自动归属地是否填写');
                }
                structure_share_str.push(obj);
              } else {
                isAllAuto = true;
                if (item.rank_group_addres == '') {
                  throw Error('请检查全自动归属地是否填写');
                }
                rank_group_id_arr.push(item.rank_group_id);
                var _obj2 = {
                  rank_group_id: item.rank_group_id,
                  name: item.name,
                  rank_group_addres: item.rank_group_addres != 0 ? item.rank_group_addres : '',
                  distribution_way: item.distribution_way
                };
                structure_share_str.push(_obj2);
              }
            });
          } catch (e) {
            validFlag = true;
            _this6.$message({
              message: e,
              type: 'warning'
            });
          }
          if (validFlag) {
            return;
          }
          var params = {
            id: '',
            is_directional: _this6.ruleForm.is_directional,
            name: _this6.ruleForm.name,
            opti: _this6.ruleForm.opti,
            // rank_group_id: this.ruleForm.rank_group_id,
            structure_share_str: structure_share_str
          };
          if (isAllAuto) {
            //rank_group_id_arr
            // this.$set(params, 'rank_group_id', this.ruleForm.rank_group_id);
            _this6.$set(params, 'rank_group_id', rank_group_id_arr.toString());
          }
          if (_this6.vals == '新增推广项目') {
            params.id = '';
            getProjectAdd(params).then(function (respomse) {
              _this6.$notify({
                title: '成功',
                message: '新增成功',
                type: 'success',
                duration: 2000
              });
              _this6.dialogProject = false;
              _this6.$refs.ruleForm.resetFields();
              _this6.getList(_this6.query.page, _this6.query.pagesize);
            }).catch(function () {});
          } else if (_this6.vals == '编辑推广项目') {
            params.id = _this6.ruleForm.id;
            getProjectEdit(params).then(function (respomse) {
              _this6.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
              _this6.fullscreenLoading = false;
              _this6.dialogProject = false;
              _this6.$refs.ruleForm.resetFields();
              _this6.getList(_this6.query.page, _this6.query.pagesize);
            }).catch(function () {});
          }
        } else {
          return false;
        }
      });
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.ruleForm.resetFields();
      this.ruleForm.bumenLabel = [];
    },
    selectRows: function selectRows(selection, row) {
      var ids = [];
      selection.map(function (item) {
        ids.push(item.id);
      });
      this.ids = ids.toString();
      console.log(this.ids);
    },
    getUserList: function getUserList() {
      var _this7 = this;
      _getUserList({
        type: '0',
        get_type: '0'
      }).then(function (res) {
        _this7.coustomer = res.data;
      });
    },
    coustomerChange: function coustomerChange(val) {
      this.coustomerName = val;
      // this.getList(this.query.page,this.query.pagesize)
    },
    bumenChange: function bumenChange(i, val, only) {
      // 循环
      var array = this.$refs["cascaderBumen".concat(i)][0].getCheckedNodes();
      var arrayNew = array.filter(function (item) {
        return !item.hasChildren;
      });
      var popularizeArr = [];
      var name = '';
      var structre_id = '';
      arrayNew.forEach(function (item, index) {
        var popularize = {};
        // console.log('arrayNew', item);
        popularize.label = item.pathLabels[1];
        popularize.project_structure = item.path[1];
        popularize.name = item.pathLabels[2];
        popularize.user_ids = item.path[2];
        popularize.pid = item.path[0];
        popularize.structre_ids = only[index].toString();
        name = item.pathLabels[0];
        structre_id = item.path[0];
        // console.log('popularize_project_structure', popularize);
        popularizeArr.push(popularize);
      });
      this.$set(this.ruleForm.bumenLabel[i], 'name', name);
      this.$set(this.ruleForm.bumenLabel[i], 'structre_id', structre_id);
      this.$set(this.ruleForm.bumenLabel[i], 'popularize_project_structure', popularizeArr);

      // this.$set(_this.ruleForm.bumenLabel[i], 'structre_id', '');
      // this.$set(_this.ruleForm.bumenLabel[i], 'name', '');
      // this.$set(_this.ruleForm.bumenLabel[i], 'popularize_project_structure', '');
      // this.$set(_this.ruleForm.bumenLabel[i], 'address', '');
      // if (val.length != 0) {
      //     let array = [];
      //     let array2 = [];
      //     let name = '';
      //     let structre_id = '';
      //     let _this = this;
      //     setTimeout(() => {
      //         array = _this.$refs['cascaderBumen'][0].checkedNodes;
      //         console.log('arrayarrayarrayarray', array);
      //         array.forEach((item) => {
      //             let popularize = {};
      //             popularize.label = item.pathLabels[1];
      //             popularize.project = item.path[1];
      //             popularize.name = item.pathLabels[2];
      //             popularize.user_ids = item.path[2];
      //             name = item.pathLabels[0];
      //             structre_id = item.path[0];
      //             array2.push(popularize);
      //         });
      //         _this.$set(_this.ruleForm.bumenLabel[i], 'popularize_project_structure', array2);
      //         _this.$set(_this.ruleForm.bumenLabel[i], 'name', name);
      //         _this.$set(_this.ruleForm.bumenLabel[i], 'structre_id', structre_id);
      //     }, 0);
      //     _this.ruleForm.bumenLabel[i].childdren_c = '0';
      // } else {
      //     this.ruleForm.bumenLabel = [];
      // }
    },
    createChange: function createChange(item, keys) {
      var _this8 = this;
      var _this = this;
      var array2 = [];
      if (item && item.length > 0) {
        // 如果打开的这个是半自动分配时，归属地不可选不限 去掉了
        // 全自动分配 都可以选

        // 如果选了不限 就不能选其他城市
        // 选了其他城市 就不能选不限
        item.forEach(function (element) {
          if (element == '0') {
            _this8.cityArray.forEach(function (element1) {
              if (element1.value == '0') {
                element1.disabled = false;
              } else {
                element1.disabled = true;
              }
            });
          } else {
            _this8.cityArray.forEach(function (element1) {
              if (element1.value == '0') {
                element1.disabled = true;
              } else {
                element1.disabled = false;
              }
            });
          }
        });
      } else {
        // if (this.ruleForm.bumenLabel[keys].distribution_way == 1) {
        //     this.cityArray.forEach((element) => {
        //         if (element.value == 0) {
        //             element.disabled = true;
        //         } else {
        //             element.disabled = false;
        //         }
        //     });
        // } else {
        this.cityArray.forEach(function (element) {
          element.disabled = false;
        });
        // }
      }
      setTimeout(function () {
        var array = _this.$refs['cascaderAddress'][keys].checkedNodes;
        array.forEach(function (element) {
          if (element.label == '不限') {
            array2.push('0');
          } else {
            array2.push(element.pathLabels[1]);
          }
        });
        // 判断当前这个是半自动还是全自动 参数名不同
        if (_this.ruleForm.bumenLabel[keys].distribution_way == 1) {
          _this8.$set(_this.ruleForm.bumenLabel[keys], 'addres', array2.toString());
        } else {
          _this8.$set(_this.ruleForm.bumenLabel[keys], 'rank_group_addres', array2.toString());
        }
      }, 0);
    },
    handleDirection: function handleDirection(val) {
      var _this9 = this;
      this.directionData = val;
      this.$nextTick(function () {
        _this9.query.page = 1;
        _this9.getList(_this9.query.page, _this9.query.pagesize);
      });
    },
    clearDirection: function clearDirection() {
      var _this10 = this;
      this.directionData = '';
      this.$nextTick(function () {
        _this10.query.page = 1;
        _this10.getList(_this10.query.page, _this10.query.pagesize);
      });
    },
    tgChange: function tgChange(val) {
      var _this11 = this;
      this.$refs.cascaderTg.dropDownVisible = false;
      var label = this.$refs['cascaderTg'].getCheckedNodes();
      var tgArr = label.map(function (value) {
        return value.label;
      }).join(',');
      // console.log('dadasdasd', tgArr);
      this.tgDepart = val;
      this.tgDeparts = tgArr;
      this.$nextTick(function () {
        _this11.query.page = 1;
        _this11.getList(_this11.query.page, _this11.query.pagesize);
      });
    },
    tgSearch: function tgSearch(val) {
      var _this12 = this;
      this.tgDepartSearch = val;
      this.$nextTick(function () {
        _this12.query.page = 1;
        _this12.getList(_this12.query.page, _this12.query.pagesize);
      });
    },
    handleEnterer: function handleEnterer(val) {
      var _this13 = this;
      this.enterer = val;
      this.$nextTick(function () {
        _this13.query.page = 1;
        _this13.getList(_this13.query.page, _this13.query.pagesize);
      });
    },
    clearEnterer: function clearEnterer() {
      var _this14 = this;
      this.enterer = '';
      this.$nextTick(function () {
        _this14.query.page = 1;
        _this14.getList(_this14.query.page, _this14.query.pagesize);
      });
    },
    getList: function getList(page, pagesize) {
      var _this15 = this;
      //账号客户列表

      this.listLoading = true;
      getProjectList(_defineProperty(_defineProperty(_defineProperty({
        page: String(page),
        pagesize: String(pagesize),
        create_id: this.coustomerName,
        //创建人
        name: this.projectSearch,
        is_directional: this.directionData,
        structre_name: String(this.tgDepartSearch)
      }, "create_id", String(this.enterer)), "start_create_time", this.screate_time), "end_create_time", this.ecreate_time)).then(function (respomse) {
        _this15.tableData = respomse.data.list.data;
        _this15.query.total = respomse.data.list.total;
        _this15.listLoading = false;
      }).catch(function () {
        _this15.tableData = [];
        _this15.listLoading = false;
      });
    },
    formatBumen: function formatBumen(row) {
      if (row.structre_arr) {
        var arr = [];
        row.structre_arr.forEach(function (item) {
          arr.push(item.name);
        });
        return arr.join('，');
      }
    },
    formatDirection: function formatDirection(row) {
      if (row.is_directional == 1) {
        return '定向数据';
      } else if (row.is_directional == 2) {
        return '非定向数据';
      } else {
        return '-';
      }
    },
    formatStatus: function formatStatus(row) {
      if (row.status == 1) {
        return '自动';
      } else if (row.status == 2) {
        return '手动';
      } else {
        return '-';
      }
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    screening: function screening(item) {
      //昨天/最近七天/30天
      this.value1 = '';
      this.storageTime = item;
      this.publicScreening(this.ptionse, this.storageTime[0], this.storageTime[1]);
    },
    searchOrder: function searchOrder() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '是否定向数据') {
        this.directionShow = true;
      } else if (item === '创建人') {
        this.enterShow = true;
      } else if (item === '创建时间') {
        this.enterTimeShow = true;
      } else if (item === '推广适用部门') {
        this.tgbmShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '创建时间') {
        this.enterTimeShow = false;
        this.screate_time = '';
        this.ecreate_time = '';
        this.enterTime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '是否定向数据') {
        this.directionShow = false;
        this.directionData = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '推广适用部门') {
        this.tgbmShow = false;
        this.tgDepart = '';
        this.tgDeparts = '';
        this.tgDepartSearch = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '创建人') {
        this.enterShow = false;
        this.enterer = '';
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    submitEnterTime: function submitEnterTime(item) {
      //订单提交时间
      this.query.page = 1;
      if (this.enterTime == null) {
        this.screate_time = '';
        this.ecreate_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.screate_time = this.enterTime[0].toString().substring(0, 10);
        this.ecreate_time = this.enterTime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    //组织部门
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.structure_idEs = adad;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    getStructure: function getStructure() {
      var _this16 = this;
      _getStructure().then(function (respomse) {
        _this16.bumenoptions = respomse.data;
      });
    },
    publicScreening: function publicScreening(tab, item1, item2) {
      //公共方法
      this.query.page = 1;
      if (tab == 1) {
        this.start_sea_time = item1;
        this.end_sea_time = item2;
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_time = item1;
        this.end_follow_time = item2;
        this.start_sea_time = '';
        this.end_sea_time = '';
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    follower: function follower() {
      // this.getList(this.query.page, this.query.pagesize);
    },
    handleOrder: function handleOrder() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    share: function share() {
      //共享客户
      if (String(this.customer_id) == '') {
        this.$message({
          type: 'info',
          message: '请选择客户'
        });
      } else {
        this.radioEs = true;
        this.mailListVisibleEs = true;
      }
    },
    staffDepartment: function staffDepartment() {
      //员工部门
      this.radioData = true;
      this.mailListVisibleData = true;
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    // 选择全自动 存储数据
    changeAutoGroupFun: function changeAutoGroupFun(index, id) {
      var _this17 = this;
      this.$forceUpdate();
      getRankGroupStructre({
        id: id
      }).then(function (respomse) {
        _this17.$set(_this17.ruleForm.bumenLabel[index], 'popularize_project_structure', respomse.data);
        // console.log('this.ruleForm.bumenLabel[index]', this.ruleForm.bumenLabel[index].popularize_project_structure);
      });
      // this.ruleForm.rank_group_id = id;
      this.getRankGroupListData.forEach(function (item) {
        if (item.id == id) {
          _this17.$set(_this17.ruleForm.bumenLabel[index], 'name', item.group_name);
        }
      });
    },
    // 判断窗口是否显示
    changeIsAllFun: function changeIsAllFun(type, index) {
      var _this18 = this;
      // 如果打开的这个是半自动分配时，归属地不可选不限
      // if (this.ruleForm.bumenLabel[index].distribution_way == 1) {
      //     this.cityArray.forEach((element) => {
      //         element.disabled = false;
      //     });
      // this.cityArray[0].disabled = true;
      // } else {
      // 全自动 可以选不限 也可以选其他
      if (this.ruleForm.bumenLabel[index].childdren_c && this.ruleForm.bumenLabel[index].childdren_c.length > 0) {
        this.ruleForm.bumenLabel[index].childdren_c.forEach(function (val) {
          if (val == 0) {
            _this18.cityArray.forEach(function (element) {
              if (element.value == 0) {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            });
          } else {
            _this18.cityArray.forEach(function (element) {
              if (element.value == 0) {
                element.disabled = true;
              } else {
                element.disabled = false;
              }
            });
          }
        });
      } else {
        this.cityArray.forEach(function (element) {
          element.disabled = false;
        });
      }
      // }
    }
  }
};