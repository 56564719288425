var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container CollegeManagement" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                      [_c("Head", { attrs: { name: _vm.title } })],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticClass: "btn", attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 10, xl: 6 } },
                      [
                        _vm._v(
                          "\n                    推广项目名称：\n                    "
                        ),
                        _c("el-input", {
                          staticStyle: { width: "240px" },
                          attrs: {
                            placeholder: "推广项目名称搜索",
                            clearable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.projectSearch,
                            callback: function ($$v) {
                              _vm.projectSearch = $$v
                            },
                            expression: "projectSearch",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.searchOrder },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["新增"],
                            expression: "['新增']",
                          },
                        ],
                        attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit("newly", "")
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.tgbmShow,
                              expression: "tgbmShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("推广适用部门："),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              placeholder: "请输入推广适用部门",
                              size: "small",
                            },
                            on: { input: _vm.tgSearch },
                            model: {
                              value: _vm.tgDepartSearch,
                              callback: function ($$v) {
                                _vm.tgDepartSearch = $$v
                              },
                              expression: "tgDepartSearch",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("推广适用部门")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.directionShow,
                              expression: "directionShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("是否定向数据："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                size: "small",
                              },
                              on: {
                                change: _vm.handleDirection,
                                clear: _vm.clearDirection,
                              },
                              model: {
                                value: _vm.directionData,
                                callback: function ($$v) {
                                  _vm.directionData = $$v
                                },
                                expression: "directionData",
                              },
                            },
                            _vm._l(_vm.directions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("是否定向数据")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.enterShow,
                              expression: "enterShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("创建人："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                size: "small",
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              on: {
                                change: _vm.handleEnterer,
                                clear: _vm.clearEnterer,
                              },
                              model: {
                                value: _vm.enterer,
                                callback: function ($$v) {
                                  _vm.enterer = $$v
                                },
                                expression: "enterer",
                              },
                            },
                            _vm._l(_vm.coustomer, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.realname, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("创建人")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.enterTimeShow,
                              expression: "enterTimeShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("创建时间："),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "360px !important" },
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "  结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "timestamp",
                            },
                            on: { change: _vm.submitEnterTime },
                            model: {
                              value: _vm.enterTime,
                              callback: function ($$v) {
                                _vm.enterTime = $$v
                              },
                              expression: "enterTime",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("创建时间")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        id: "customerTable",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                      },
                      on: { "selection-change": _vm.selectRows },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "50" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: 0,
                        attrs: { label: "序号", align: "center", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3946342523
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: 1,
                        attrs: {
                          label: "推广项目名称",
                          prop: "name",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          key: 2,
                          attrs: {
                            label: "推广适用部门",
                            prop: "structre_arr",
                            align: "center",
                            width: "240",
                            formatter: _vm.formatBumen,
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("推广适用部门")
                                  },
                                },
                              },
                              [_vm._v("推广适用部门")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          key: 3,
                          attrs: {
                            label: "是否定向数据",
                            prop: "is_directional",
                            align: "center",
                            width: "140px",
                            formatter: _vm.formatDirection,
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("是否定向数据")
                                  },
                                },
                              },
                              [_vm._v("是否定向数据")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          key: 4,
                          attrs: {
                            label: "创建人",
                            prop: "create_realname",
                            align: "center",
                            width: "180",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("创建人")
                                  },
                                },
                              },
                              [_vm._v("创建人")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          key: 5,
                          attrs: {
                            label: "创建时间",
                            prop: "create_time",
                            align: "center",
                            width: "280",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("创建时间")
                                  },
                                },
                              },
                              [_vm._v("创建时间")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "160", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(
                                            "edit",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4154370513
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "tableLeft",
                      attrs: { id: "tableLeft" },
                      on: {
                        scroll: function ($event) {
                          return _vm.exterHandleScroll()
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "10px" },
                        attrs: { id: "bodyLeft" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      staticStyle: { margin: "0 auto" },
                      attrs: {
                        title: _vm.vals,
                        visible: _vm.dialogProject,
                        width: "1100px",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogProject = $event
                        },
                        close: _vm.cancel,
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "120px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "推广项目名称", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "50" },
                                on: { blur: _vm.checkName },
                                model: {
                                  value: _vm.ruleForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "name", $$v)
                                  },
                                  expression: "ruleForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否定向数据",
                                prop: "is_directional",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.is_directional,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "is_directional",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.is_directional",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("定向数据"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("非定向数据"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-form-item", {
                            attrs: { label: "推广适用部门" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { staticClass: "shares" },
                            _vm._l(
                              _vm.ruleForm.bumenLabel,
                              function (item, key) {
                                return _c(
                                  "el-form-item",
                                  { key: key, attrs: { label: "分配方式：" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "bumenItem" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100px" },
                                            attrs: { placeholder: "请选择" },
                                            on: {
                                              change: function (val) {
                                                return _vm.optisea(key, val)
                                              },
                                            },
                                            model: {
                                              value: item.distribution_way,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "distribution_way",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.distribution_way",
                                            },
                                          },
                                          _vm._l(_vm.optionss, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                        _vm._v(" "),
                                        item.distribution_way == 1
                                          ? _c("el-cascader", {
                                              ref: "cascaderBumen" + key,
                                              refInFor: true,
                                              staticStyle: {
                                                width: "190px",
                                                "margin-left": "20px",
                                              },
                                              attrs: {
                                                options: _vm.bumenoptions,
                                                clearable: "",
                                                "collapse-tags": "",
                                                props: {
                                                  value: "suid",
                                                  label: "name",
                                                  multiple: true,
                                                },
                                              },
                                              on: {
                                                change: function (val) {
                                                  return _vm.bumenChange(
                                                    key,
                                                    item.structure_share,
                                                    val
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.structure_share,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "structure_share",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.structure_share",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.distribution_way == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "disabled-input-class",
                                                attrs: { disabled: true },
                                              },
                                              _vm._l(
                                                _vm.ruleForm.bumenLabel[key]
                                                  .popularize_project_structure,
                                                function (i, key) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: key,
                                                      staticStyle: {
                                                        display: "flex",
                                                        "line-height": "20px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(i.label) +
                                                          "/" +
                                                          _vm._s(i.name)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.distribution_way == 2
                                          ? _c(
                                              "el-select",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "190px",
                                                },
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                on: {
                                                  change: function (val) {
                                                    return _vm.changeAutoGroupFun(
                                                      key,
                                                      val
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.rank_group_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "rank_group_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.rank_group_id",
                                                },
                                              },
                                              _vm._l(
                                                _vm.getRankGroupListData,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.id,
                                                    attrs: {
                                                      label: item.group_name,
                                                      value: item.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.distribution_way == 2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "disabled-input-class",
                                                attrs: { disabled: true },
                                              },
                                              _vm._l(
                                                _vm.ruleForm.bumenLabel[key]
                                                  .popularize_project_structure,
                                                function (i, key) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: key,
                                                      staticStyle: {
                                                        display: "flex",
                                                        "line-height": "20px",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(i.name))]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "demonstration" },
                                          [
                                            _c("span", [_vm._v("归属地")]),
                                            _vm._v(" "),
                                            _c("el-cascader", {
                                              ref: "cascaderAddress",
                                              refInFor: true,
                                              staticStyle: { width: "145px" },
                                              attrs: {
                                                options: _vm.cityArray,
                                                props: {
                                                  value: "value",
                                                  label: "label",
                                                  multiple: true,
                                                },
                                                "collapse-tags": "",
                                                placeholder: "归属地默认为不限",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.createChange(
                                                    item.childdren_c,
                                                    key
                                                  )
                                                },
                                                "visible-change": function (
                                                  val
                                                ) {
                                                  return _vm.changeIsAllFun(
                                                    val,
                                                    key
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm.bumenLabel[key]
                                                    .childdren_c,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm.bumenLabel[
                                                      key
                                                    ],
                                                    "childdren_c",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.bumenLabel[key].childdren_c",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("el-button", {
                                          staticStyle: {
                                            "margin-left": "1%",
                                            height: "40px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                            icon: "el-icon-plus",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.classification()
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        key !== 0
                                          ? _c("el-button", {
                                              staticStyle: {
                                                "margin-left": "1%",
                                                height: "40px",
                                              },
                                              attrs: {
                                                type: "danger",
                                                size: "small",
                                                icon: "el-icon-close",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DeleteCondition(
                                                    key
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          staticStyle: { "text-align": "right" },
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialogProject = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading.fullscreen.lock",
                                  value: _vm.fullscreenLoading,
                                  expression: "fullscreenLoading",
                                  modifiers: { fullscreen: true, lock: true },
                                },
                              ],
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.createData("ruleForm")
                                },
                              },
                            },
                            [_vm._v("确认")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }